export const USERNAME_MIN_LENGTH = 4
export const USERNAME_MAX_LENGTH = 30

export const DISPLAYNAME_MIN_LENGTH = 4
export const DISPLAYNAME_MAX_LENGTH = 50

export const ACCOUNT_STATUS: { [key: string]: string } = {
  active: 'Active',
  pending: 'Pending',
  suspended: 'Suspended',
  deactivated: 'Deactivated',
  closed: 'Closed',
}

export const PROFILE_STATUS: { [key: string]: string } = {
  public: 'Public',
  private: 'Private',
}

export const CONNECTION_TYPES: { [key: string]: string } = {
  apple: 'apple',
  facebook: 'facebook',
  google: 'google-oauth2',
  sms: 'sms',
  windows: 'windowslive',
  database: 'Dev-BP-DB',
}

export const SCREEN_HINT: { [key: string]: string } = {
  signup: 'signup',
}

export const ONBOARDING: { [key: string]: string } = {
  loading: 'LOADING',
  complete: 'COMPLETE',
  pending: 'PENDING',
  processed: 'PROCESSED',
}

export const ONBOARDING_STEPS = {
  verifyemail: 'verifyemail',
  dob: 'dob',
  username: 'username',
  displayname: 'displayname',
  profilepicture: 'profilepicture',
  gender: 'gender',
  follow: 'follow',
  location: 'location',
  interests: 'interests',
  finish: 'finish',
}

export const HTTP_STATUS = {
  OK: 200,
  UNAUTHORIZED: 401,
}

// TODO: Move to env after merging with Marcin changes
export const AUTH0_NAMESPACE = 'com.interactiveone.blackplanet'

export const AUTH0_SCOPE = 'openid profile email offline_access'

export const ONBOARDING_STATUS: { [key: string]: string } = {
  active: 'ACTIVE',
  loading: 'LOADING',
  complete: 'COMPLETE',
  pending: 'PENDING',
  processed: 'PROCESSED',
}

export const EMAIL_STATUS: { [key: string]: string } = {
  unverified: 'Unverified',
  verified: 'Verified',
}
