import { useEffect, useRef } from 'react'
import { AppState } from 'react-native'
import { useAuth } from 'app/lib/auth'
import { useStream } from 'app/lib/stream/useStream'

const TIMEOUT = Number(process.env.BP_PUBLIC_GS_DISCONNECT_TIMEOUT) || 30000

export function useStreamAppState({ handleDisconnect = () => {}, handleConnect = () => {} }) {
  const { isAuthenticated } = useAuth()
  const activeChatAudioChannel = useStream((s) => s.activeChatAudioChannel)
  const disconnectedRef = useRef(false)
  const subscriptionRef = useRef(false)
  const timeoutIdRef = useRef(null)

  const handleAppStateChange = (appState) => {
    if (appState === 'background') {
      timeoutIdRef.current = setTimeout(() => {
        handleDisconnect()
        disconnectedRef.current = true
      }, TIMEOUT)
    } else {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current)
        timeoutIdRef.current = null
      }
      if (disconnectedRef.current) {
        handleConnect()
        disconnectedRef.current = false
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    if (subscriptionRef.current) {
      subscriptionRef.current.remove()
      subscriptionRef.current = false
    }

    // do not disconnect GS chat and video if there is a active chat room
    if (activeChatAudioChannel) {
      return
    }

    subscriptionRef.current = AppState.addEventListener('change', handleAppStateChange)

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.remove()
        subscriptionRef.current = false
      }
    }
  }, [activeChatAudioChannel])
}
