import { Stack, EmailIcon, Button } from '@bp/ui'
import React from 'react'
import { MessagingUnreadCount } from './MessagingUnreadCount'
import { useRouter } from 'solito/router'

export function MessagingTabIcon() {
  const { push } = useRouter()

  const handleMessage = () => {
    push('/messages')
  }

  return (
    <Stack ai="center" jc="center">
      <MessagingUnreadCount />
      <Button variant="text" onPress={handleMessage} icon={<EmailIcon size={24} />}></Button>
    </Stack>
  )
}
