;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"0f8bbdbefce0c1d507b3fb34f0d405d0cbfd0c71"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { SENTRY_DSN } from 'app/config'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: "light",
      enableScreenshot: true,
      showBranding: false,
      showName: false,
      themeLight: {
        accentBackground: '#006A7C',
      }
    }),
    // TODO: Revisit for launch
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Crash reporting dialog
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception && event.event_id) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // },
})

