import { createStore } from 'zustand'
import { connect as feedsConnect } from 'getstream'
import { StreamChat } from 'stream-chat'
import { StreamVideoClient } from 'app/lib/stream/video'
import { useUserInfoStore } from 'app/store'
import API from 'app/api'
import { queryPublicRooms, queryPrivateRooms } from 'app/features/chat'

const apiKey = process.env.BP_PUBLIC_GS_KEY
const appId = process.env.BP_PUBLIC_GS_ID

const defaultProps = {
  feeds: false,
  feedsConnecting: false,
  feedsConnected: false,
  feedsConnectionError: false,
  chat: false,
  chatConnecting: false,
  chatConnected: false,
  chatConnectionError: false,
  video: false,
  videoReady: false,
  videoConnecting: false,
  videoConnected: false,
  videoConnectionError: false,
  activeChatAudioChannel: false,
  activeChatMessagingChannel: false,
  activeMessagingChannel: false,
  showMessageRequests: false,
  messagingSearchText: '',
  loading: false,
  rooms: false,
  joinCallError: false,
  joinChannelError: false,
  joinChatAudioChannelError: false,
  joinChatMessaginChannelError: false,
  disconnected: false,
  splitScreen: false,
  privateChatRooms: [],
  publicChatRooms: [],
  chatRoomsLoading: false,
  messagingChannelUnreadCount: 0,
}

const store = createStore()((set, get) => ({
  ...defaultProps,
  connectFeeds: async () => {
    set({
      feedsConnecting: true,
      feedsConnected: false,
      feedsConnectionError: false,
    })
    console.info('Connecting Feeds:')
    try {
      // Connect to getstream feed client
      const { access_token: feedToken } = await API.user.getStreamIoToken()
      const feedsClient = feedsConnect(apiKey, feedToken, appId)
      set({ feeds: feedsClient, feedsConnected: true, feedsConnecting: false })
      console.info('Connected to Feeds:')
    } catch (error) {
      console.error('Error connecting Feeds:', error)
      set({
        feedsConnecting: false,
        feedsConnected: false,
        feedsConnectionError: true,
      })
    }
  },
  connect: async () => {
    const userInfo = useUserInfoStore.getState()
    const userId = userInfo?.id?.toString()
    console.info('Connecting Chat and Video & Audio client:', 'userId:', userId)
    set({
      chat: false,
      chatConnecting: true,
      chatConnected: false,
      chatConnectionError: false,
      videoConnecting: true,
      videoConnected: false,
      videoConnectionError: false,
      disconnected: false,
    })

    try {
      const { access_token: token } = await API.stream.getChatAccessToken()
      const user = { id: userId }

      // Connect to getstream chat client

      const chatclient = StreamChat.getInstance(apiKey)
      await chatclient.connectUser(user, token)
      // Unread messages count
      // chatclient provides unread message count across all channels (messaging, public etc)
      // display only unread messages in messaging channel

      const getUnreadMessagingChannel = async (client) => {
        const unreadChannels = await client?.getUnreadCount()
        const unreadMessagingChannel = unreadChannels?.channel_type?.find(
          (channel) => channel.channel_type === 'messaging'
        )

        return unreadMessagingChannel
      }

      const unreadMessagingChannel = await getUnreadMessagingChannel(chatclient)
      set({ messagingChannelUnreadCount: unreadMessagingChannel?.unread_count || 0 })

      chatclient.on(async (event) => {
        // Listen only messaging channel for events
        // Skip chat room related channels (public, private)
        // Notification for public, private chat rooms will be turned off
        if (event.type === 'health.check' || event?.channel_type !== 'messaging') {
          return
        }

        const { user, type, total_unread_count } = event
        const updateUnreadCount = (count) => {
          set({ messagingChannelUnreadCount: count })
        }

        switch (type) {
          case 'notification.message_new':
            updateUnreadCount(total_unread_count || 0)
            break

          case 'notification.mark_read':
            if (user?.id === userId) {
              updateUnreadCount(total_unread_count || 0)
            }
            break

          case 'message.new':
            if (user?.id !== userId) {
              updateUnreadCount(total_unread_count)
            }
            break

          case 'message.read':
            if (user?.id === userId) {
              // 'message.read' event does not return unread_count
              // make api call
              const unreadMessagingChannel = await getUnreadMessagingChannel(chatclient)
              updateUnreadCount(unreadMessagingChannel?.unread_count)
            }
            break

          default:
            break
        }
      })
      set({ chat: chatclient, chatConnected: true, chatConnecting: false })

      // Connect to getstream video & audio client
      const videoClient = StreamVideoClient.getOrCreateInstance({ apiKey, token, user })
      set({ video: videoClient, videoConnected: true, videoConnecting: false })
      console.info('Connected to Chat and Video & Audio client:', 'userId:', userId)
    } catch (error) {
      console.error('Error connecting Chat and Video & Audio client:', error)
      set({
        chatConnecting: false,
        chatConnected: false,
        chatConnectionError: true,
        videoConnecting: false,
        videoConnected: false,
        videoConnectionError: true,
      })
    }
  },
  disconnect: async () => {
    console.info('Disconnecting Chat and Video & Audio client:')
    try {
      // No disconnect for feeds client
      await get().chat?.disconnectUser()
      console.info('Disconnected Chat and Video & Audio client:')
    } catch (error) {
      console.info('Error disconnecting Chat and Video & Audio client:', error)
    }

    set({
      chat: false,
      chatConnected: false,
      video: false,
      videoConnected: false,
      activeChatAudioChannel: false,
      activeChatMessagingChannel: false,
      activeMessagingChannel: false,
      disconnected: true,
    })
  },
  joinChatAudioChannel: async (roomType, roomId) => {
    set({ joinChatAudioChannelError: false })
    try {
      const call = await get().video?.call(roomType, roomId)
      await call?.join()
      set({ activeChatAudioChannel: call })
    } catch (error) {
      set({
        joinChatAudioChannelError:
          error?.response?.data?.message || 'Error joining video/audio room',
      })
    }
  },
  joinChatMessagingChannel: async (roomType, roomId) => {
    set({ joinChatMessagingChannelError: false })
    try {
      const filter = { type: roomType, id: roomId }
      const sort = [{ last_message_at: -1 }]
      const channels = await get().chat?.queryChannels(filter, sort, { watch: true, state: true })
      const channel = channels[0]
      await channel?.watch()
      set({ activeChatMessagingChannel: channel })
    } catch (error) {
      set({
        joinChatMessagingChannelError: error?.response?.data?.message || 'Error joining chatroom',
      })
    }
  },
  endChatAudioChannel: () => {
    const call = get().activeChatAudioChannel
    if (call) {
      call?.endCall()
    }
    set({ activeChatAudioChannel: false, joinChatAudioChannelError: false })
  },
  endChatMessagingChannel: async () => {
    const channel = get().activeChatMessagingChannel
    if (channel) {
      try {
        await channel?.delete()
      } catch (error) {
        console.error('endChatMessagingChannel error:', error)
      }
    }
    set({ activeChatMessagingChannel: false, joinChatMessagingChannelError: false })
  },
  endChatRoomChannels: async () => {
    const { endChatAudioChannel, endChatMessagingChannel } = get()
    try {
      await endChatAudioChannel()
      await endChatMessagingChannel()
      set({ requests: [] })
    } catch (error) {
      console.error('endChatRoomChannels error:', error)
    }
  },
  leaveChatAudioChannel: async () => {
    const call = get().activeChatAudioChannel
    const isActive = !call?.state?.endedAt
    if (isActive) {
      try {
        call?.leave && (await call?.leave())
      } catch (error) {
        console.error('leaveChatAudioChannel error:', error)
      }
    }
    set({ activeChatAudioChannel: false, joinChatAudioChannelError: false })
  },
  leaveChatMessagingChannel: async () => {
    const channel = get().activeChatMessagingChannel
    if (channel) {
      await channel.stopWatching()
    }
    set({ activeChatMessagingChannel: false, joinChatMessagingChannelError: false })
  },
  setActiveMessagingChannel: (channel) => {
    const userInfo = useUserInfoStore.getState()
    const userId = userInfo?.id?.toString()
    const requestsMembers = channel?.data?.requests || []
    const isRequests = requestsMembers?.includes(userId)
    set({ activeMessagingChannel: channel, showMessageRequests: isRequests })
  },
  setMessagingSearchText: (text) => {
    set({ messagingSearchText: text })
  },
  setShowMessageRequests: (value) => {
    set({ showMessageRequests: value })
  },
  setSplitScreen: (value) => {
    set({ splitScreen: value })
  },
  clear: () => {
    set({ ...defaultProps })
  },
  setPrivateChatRooms: (privateChatRooms) => {
    set({ privateChatRooms })
  },
  setPublicChatRooms: (publicChatRooms) => {
    set({ publicChatRooms })
  },
  setActiveChatAudioChannel: (channel) => {
    set({ activeChatAudioChannel: channel })
  },
  setActiveChatMessagingChannel: (channel) => {
    set({ activeChatMessagingChannel: channel })
  },
  setChatRoomsLoading: (value) => {
    set({ chatRoomsLoading: value })
  },

  fetchChatRooms: async () => {
    const { setChatRoomsLoading, setPublicChatRooms, setPrivateChatRooms, video } = get()
    setChatRoomsLoading(true)
    try {
      const publicRooms = await queryPublicRooms(video)
      setPublicChatRooms([...publicRooms])
      const privateRooms = await queryPrivateRooms(video)
      setPrivateChatRooms([...privateRooms])
    } catch (error) {
      console.error('Error fetching chat rooms', error)
    }
    setChatRoomsLoading(false)
  },
}))
export default store
