import http from './http'
import httpAuth0 from './httpAuth0'

const API = {
  public: {
    getInfo: (uname) => http.get('/public/user/profile', { uname }),
    getCard: (uname) => http.get('/public/user/card', { uname }),
  },
  post: {
    createPost: (body, type = 'text', images = null, preview = null, links = null) =>
      http.post('/post/create', { body, type, images, preview, links }),
    deletePost: (pid, type = 'text', is_legacy = 0) =>
      http.post('/post/delete', { pid, type, is_legacy }),
    createComment: (pid, body, type = 'text', images = undefined) =>
      http.post('/comment/create', { pid, body, type, images }),
    getComments: (pid, type = 'text') => http.get('/post/comments', { pid, type }),
    like: (pid) => http.post('/post/reaction/add/like', { pid }),
    unlike: (pid) => http.post('/post/reaction/remove/like', { pid }),
  },
  flag: {
    flagPost: (pid, type = 'text', is_legacy = 0) =>
      http.post(`/flag/post`, { pid, type, is_legacy }),
  },
  account: {
    ifUsernameExists: (username) => http.get('/account/username/exists', { username }),
    ifEmailExists: (email) => http.get('/account/email/exists', { email }),
    completeOnboarding: () => http.post('/account/set/onboarding/done'),
    setProfile: () => http.post('/account/set/profile'),
    setNotifications: (notificationType) =>
      http.post(`/account/set/notification/${notificationType}`),
    activate: () => http.post('/account/activate'),
    deactivate: () => http.post('/account/deactivate'),
    delete: () => http.post('/account/delete'),
  },
  auth: {
    getCurrentUserInfo: () => http.get('/user'),
    setLastLogin: () => http.post('/user/set/last/login'),
  },
  chat: {
    getAccessToken: () => http.get(`/chat/streamio/token`),
  },
  user: {
    syncContacts: (contacts, return_suggested = 1) =>
      http.post('/user/sync/contacts', contacts, { return_suggested }),
    getSuggestedUsers: (limit = 30) => http.get('/user/suggested', { limit }),
    follow: (uid) => http.post('/user/follow', null, { uid }),
    unfollow: (uid) => http.post('/user/unfollow', null, { uid }),
    blockedByMe: (limit = 10, offset = 0) => http.get('/user/blocked_by_me', { limit, offset }),
    block: (uid) => http.post('/user/block', { uid }),
    unblock: (uid) => http.post('/user/unblock', { uid }),
    getStreamIoToken: () => http.get('/user/streamio/token'),
  },
  profile: {
    setAvatar: (img) => http.post('/user/profile/set/avatar', { img }),
    setBackground: (img) => http.post('/user/profile/set/page_cover', { img }),
    setBio: (bio) => http.post('/user/profile/set/bio', { bio }),
    getInfo: (uname) => http.get('/user/profile', { uname }),
    getCard: (uname) => http.get('/user/card', { uname }),
  },
  settings: {
    accountInfo: () => http.get('/account/settings'),
    changeEmail: (email) => httpAuth0.post('/api/auth0/change_email', { email }),
    setDob: (dob) => http.post('/account/set/dob', { dob }),
    setUsername: (username) => http.post('/account/set/username', { username }),
    setDisplayName: (display_name) => http.post('/account/set/display_name', { display_name }),
    setEmail: (email) => http.post('/account/set/email', { email }),
    setInterests: (ids) => http.post('/account/set/interests', { ids }),
    setLocation: (location) => http.post('/account/set/location', { location }),
    setGender: (gender) => http.post('/account/set/gender', { gender }),
    setPassword: (current_password, new_password) =>
      http.post('/account/set/password', { current_password, new_password }),
  },
  util: {
    getInterests: () => http.get('/ref/interests'),
    getGenders: () => http.get('/ref/genders'),
  },
  search: {
    getFollowingFollowers: (value) =>
      http.get(`/search/following-followers?per_page=20&q=${value}`),
    getPeople: (value) => http.get(`/search/people?q=${value}`),
  },
  stream: {
    getChatAccessToken: () => http.get(`/chat/streamio/token`),
    createMessagingChannel: (members) => http.post(`/dm/create/channel`, { members }),
    updateMessagingChannel: ({ title, channelId }) =>
      http.post(`/dm/update/channel`, { title, channel_id: channelId }),
    createChatChannel: ({ members, title, channel_type }) =>
      http.post(`/chat/create/channel`, {
        members,
        title,
        channel_type,
        access_request_enabled: 1,
      }),
  },
}

export default API
