/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */

import { Logo as BPLogo, Button, Text } from '@bp/ui'
import { useLink } from 'solito/link'
import React from 'react'
import { isDevelopmentOrInternalBuild, getBuildType } from 'app/utils' // TODO: We need some way to check build string/hash, okay solution for now.

export default function Logo() {
  const logoProps = useLink({
    href: '/',
  })

  let buildString = `v0.2.0${getBuildType().charAt(0)}`

  return (
    <Button variant="link" {...logoProps}>
      <BPLogo scale={0.75} />
      {isDevelopmentOrInternalBuild() && (
        <Text fontWeight="bold" style={{ position: 'absolute' }} centered>
          {buildString}
        </Text>
      )}
    </Button>
  )
}
