// Try to import Expo Constants, but don't fail if it's not available
let Constants = null
try {
  Constants = require('expo-constants').default
} catch (e) {
  console.log('Expo Constants not available:', e instanceof Error ? e.message : String(e))
}

export const BuildTypes = {
  DEVELOPMENT: 'development',
  INTERNAL: 'internal',
  PRODUCTION: 'production',
}

// Non-hook version for use outside of components
export const getBuildType = () => {
  // Always development in __DEV__ mode
  if (typeof __DEV__ !== 'undefined' && __DEV__) {
    return BuildTypes.DEVELOPMENT
  }

  // Using Expo Constants if available
  try {
    if (Constants) {
      // Check release channel
      if (Constants.releaseChannel) {
        if (['internal', 'beta', 'staging'].includes(Constants.releaseChannel)) {
          return BuildTypes.INTERNAL
        }
        if (['production', 'store'].includes(Constants.releaseChannel)) {
          return BuildTypes.PRODUCTION
        }
      }

      // If no release channel but not a standalone app, it's likely development
      if (Constants.appOwnership !== 'standalone') {
        return BuildTypes.DEVELOPMENT
      }
    }
  } catch (error) {
    // Constants not available or there was an error accessing it
    console.log('Error accessing Expo Constants:', error)
  }

  // Fall back to production if we couldn't determine
  return BuildTypes.PRODUCTION
}

export const isDevelopmentBuild = () => getBuildType() === BuildTypes.DEVELOPMENT
export const isInternalBuild = () => getBuildType() === BuildTypes.INTERNAL
export const isProductionBuild = () => getBuildType() === BuildTypes.PRODUCTION
export const isDevelopmentOrInternalBuild = () => isDevelopmentBuild() || isInternalBuild()
