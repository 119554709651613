import { useState, useRef } from 'react'
import { Popover } from 'tamagui'
import { decode } from 'base-64'
global.atob = decode
import { Text, YStack, Spinner } from '@bp/ui'
import React, { useEffect } from 'react'
import { NotificationsIcon, Button } from '@bp/ui'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Notification from './notification'
import { CloseCrossIcon } from '@bp/ui/src/Iconsx'
import { useStream } from 'app/lib/stream/useStream'
import { useStreamAppState } from 'app/lib/stream/useStreamAppState'
import { NotificationBadge } from 'app/features/layout/NotificationBadge'

export function BellPopover({ accessToken, userId, ...props }) {
  const queryClient = useQueryClient()
  const client = useStream((s) => s.feeds)
  const notificationFeed = client.feed('notification', userId)
  const subscriptionRef = useRef(null)

  const subscribeFeed = () => {
    console.info('Feed notification subscribed for Activity:', 'userId:', userId)
    const callback = (data) => {
      queryClient.invalidateQueries({ queryKey: ['notificationsData'] })
    }
    const successCallback = () => {}
    const failCallback = (data) => {
      console.error('subscribe error', data)
    }
    subscriptionRef.current = notificationFeed
      .subscribe(callback)
      .then(successCallback, failCallback)
  }

  const unsubscribeFeed = () => {
    console.info('Unsubscribed feed notification for Activity:', 'userId:', userId)
    if (subscriptionRef.current && subscriptionRef.current.cancel) {
      subscriptionRef.current.cancel()
      subscriptionRef.current = null
    }
  }

  useEffect(() => {
    subscribeFeed()
    return () => {
      unsubscribeFeed()
    }
  }, [])

  useStreamAppState({
    handleConnect: subscribeFeed,
    handleDisconnect: unsubscribeFeed,
  })

  if (!client) {
    return null
  }

  const { data: notificationsData, isPending: notificationsIsPending } = useQuery({
    queryKey: ['notificationsData'],
    queryFn: () => notificationFeed.get({ limit: 20 }),
  })

  const handleOpenNotifications = () => {
    setIsOpen((v) => !v)
    notificationFeed.get({ limit: 20, mark_seen: true })
  }

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover open={isOpen} onOpenChange={(v) => setIsOpen(v)} size="$5" allowFlip {...props}>
      <Popover.Trigger asChild>
        <YStack justifyContent="center">
          <Button
            variant="text"
            onPress={handleOpenNotifications}
            icon={<NotificationsIcon size={24} />}
          />

          {notificationsIsPending ? (
            <YStack style={{ position: 'absolute', pointerEvents: 'none' }}>
              <Spinner size={16} />
            </YStack>
          ) : (
            <NotificationBadge count={notificationsData.unseen} />
          )}
        </YStack>
      </Popover.Trigger>

      <Popover.Content
        padding={0}
        paddingBottom="$2"
        borderWidth={1}
        borderColor="$borderColor"
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        elevate
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
      >
        <Popover.Arrow borderWidth={1} borderColor="$borderColor" />

        <Popover.Close asChild>
          <YStack alignSelf="flex-end">
            <Button
              onPress={() => {
                setIsOpen(false)
              }}
            >
              <CloseCrossIcon />
            </Button>
          </YStack>
        </Popover.Close>

        <YStack>
          <YStack>
            {notificationsData?.results?.map((notification) => (
              <Notification
                notification={notification}
                key={notification.id}
                setIsOpen={setIsOpen}
                notificationFeed={notificationFeed}
              />
            ))}
            {notificationsData?.results?.length === 0 && (
              <Text padding="$4">You are up to date!</Text>
            )}
          </YStack>
        </YStack>
      </Popover.Content>
    </Popover>
  )
}
