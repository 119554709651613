import { useEffect } from 'react'
import { useStream } from 'app/lib/stream/useStream'
import { useStreamPushNotification } from 'app/lib/stream/useStreamPushNotification'
import { useStreamAppState } from 'app/lib/stream/useStreamAppState'
import { useAuth } from 'app/lib/auth'

export function StreamCheckPoint({ children }) {
  const { isAuthenticated } = useAuth()
  const connectFeeds = useStream((s) => s.connectFeeds)
  const connect = useStream((s) => s.connect)
  const disconnect = useStream((s) => s.disconnect)
  const feedsConnected = useStream((s) => s.feedsConnected)
  const feedsConnecting = useStream((s) => s.feedsConnecting)
  const chatConnected = useStream((s) => s.chatConnected)
  const chatConnecting = useStream((s) => s.chatConnecting)
  const videoConnected = useStream((s) => s.videoConnected)
  const videoConnecting = useStream((s) => s.videoConnecting)
  const disconnected = useStream((s) => s.disconnected)
  // Connect to GS chat and video/audio client
  const connecting = chatConnecting || videoConnecting
  const connected = chatConnected && videoConnected
  const canConnectChats = !connected && !connecting && !disconnected && isAuthenticated
  const canConnectFeeds = !feedsConnected && !feedsConnecting && isAuthenticated

  // Notification permission and device registration
  useStreamPushNotification()

  // Disconnect idle users to reduce concurrent users
  useStreamAppState({
    handleConnect: () => {
      connect()
    },
    handleDisconnect: () => {
      disconnect()
    },
  })

  useEffect(() => {
    if (!canConnectChats) {
      return
    }

    connect()

    return () => {
      if (connected) {
        disconnect()
      }
    }
  }, [canConnectChats])

  useEffect(() => {
    if (!canConnectFeeds) {
      return
    }
    connectFeeds()
    return () => {
      // Note: No disconnect for feeds client
    }
  }, [canConnectFeeds])

  return <>{children}</>
}
