/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */

import { useAuth } from 'app/lib/auth'
import {
  DelayedTextField,
  Button,
  XStack,
  SearchIcon,
  useMedia,
  YStack,
  Text,
  Avatar as BareAvatar,
  NiceLink,
} from '@bp/ui'

import Logo from './logo'
import { useState } from 'react'
import Avatar from './avatar'
import Bell from '../bell'
import API from 'app/api'
import useClickAway from 'app/hooks/useClickAway'
import { MessagingTabIcon } from '../messaging'
import { useSearchPeople } from 'app/lib/hooks/useSearch'

export default function Header({ hideHeader, accountInfoBannerHeight }) {
  const { user, isLoading, login, signup, accessToken } = useAuth()
  const media = useMedia()

  const {
    searchResults,
    searchText,
    handleTextChange,
    clearSearch
  } = useSearchPeople()

  const searchResultsRef = useClickAway(clearSearch)

  const handleKeyPress = (e) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      clearSearch()
    }
  }

  if (hideHeader) {
    return null
  }

  return (
    <XStack height={72 + accountInfoBannerHeight}>
      <XStack
        position="fixed"
        height={72}
        elevation={3}
        width="100%"
        alignItems="center"
        backgroundColor="$lightest"
        zIndex="999"
        justifyContent="space-between"
        px="$4"
        gap="$6"
        $xxs={{ gap: '$3' }}
        top={accountInfoBannerHeight}
      >
        <XStack>
          <Logo scale={0.75} />
        </XStack>
        <YStack flexShrink={1} flexGrow={1}>
          <YStack ref={searchResultsRef} flexShrink={1} flexGrow={1}>
            <DelayedTextField
              flexShrink={1}
              flexGrow={1}
              placeholder="Search for people"
              accessibilityLabel="Search for people"
              onChangeText={handleTextChange}
              value={searchText}
              startIcon={<SearchIcon />}
              onKeyPress={handleKeyPress}
              $xxs={{ fontSize: '$4' }}
            />
            {searchResults && (
              <YStack
                position="absolute"
                top={43}
                background="$lightest"
                padding="$5"
                borderRadius="$4"
                borderColor="$light"
                borderWidth={1}
                gap="$4"
                alignItems="flex-start"
                overflow="hidden"
              >
                {searchResults.length > 0 &&
                  searchResults.map((user, index) => (
                    <NiceLink
                      href={`/${user.username}`}
                      onPress={clearSearch}
                      key={index}
                    >
                      <XStack gap="$3" key={user.id} alignItems="center">
                        <BareAvatar circular size="$4" src={user.avatar?.md} />
                        <YStack gap="$2">
                          <Text>{user.nickname}</Text>
                          <Text variant="footnote" color="$medium">
                            @{user.username}
                          </Text>
                        </YStack>
                      </XStack>
                    </NiceLink>
                  ))}
                {searchResults.length === 0 && (
                  <XStack gap="$3" alignItems="center" paddingHorizontal="$5">
                    <Text>No results found</Text>
                  </XStack>
                )}
              </YStack>
            )}
          </YStack>
        </YStack>

        {accessToken && user && !isLoading ? (
          <XStack gap="$4">
            {media.xxs && (
              <Button variant="text" icon={<SearchIcon size={24} />} gtXss={{ display: 'none' }} />
            )}
            <MessagingTabIcon />
            <Bell />
            <Avatar />
          </XStack>
        ) : (
          <XStack gap="$2">
            <Button variant="primary" onPress={login}>
              Login
            </Button>
            <Button variant="accent" onPress={signup}>
              Signup
            </Button>
          </XStack>
        )}
      </XStack>
    </XStack>
  )
}
