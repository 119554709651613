import { createContext, useState, useEffect } from 'react'
import { Auth0Provider as AuthProvider, useAuth0 } from '@auth0/auth0-react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { SCREEN_HINT, AUTH0_SCOPE } from '../../utils/constants'
import { AuthContextType } from './types.D'
import { useAccountInfoStore, useUserInfoStore } from 'app/store'
import store from 'app/lib/stream/store'
import { usePostLogin } from './usePostLogin'
import { setAuthContext } from '../../api/http'

let auth0Domain: string,
  auth0ClientId: string,
  auth0Audience: string,
  auth0RedirectUri: string = ''

if (
  process.env.BP_PUBLIC_AUTH0_DOMAIN &&
  process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID &&
  process.env.BP_PUBLIC_AUTH0_AUDIENCE
) {
  auth0Domain = process.env.BP_PUBLIC_AUTH0_DOMAIN
  auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
  auth0Audience = process.env.BP_PUBLIC_AUTH0_AUDIENCE
} else {
  // TODO: Sentry integration
  throw new Error('Auth0 domain or client id is missing in Auth Provider')
}

if (typeof window !== 'undefined') {
  auth0RedirectUri = window.location.origin
}

export const BPAuthContext = createContext<AuthContextType | object>({})

export const BPAuthProvider = ({ children }: { children: React.ReactElement }) => {
  const { loginWithRedirect, logout, user, error, isLoading, getAccessTokenSilently, loginWithPopup } = useAuth0()
  const [accessToken, setAccessToken] = useState('')
  const clearAccountInfo = useAccountInfoStore((s) => s.clear)
  const clearUserInfo = useUserInfoStore((s) => s.clear)
  const streamStore = store.getState()

  usePostLogin({ accessToken })

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently()
      setAccessToken(token)
      await AsyncStorage.setItem('token', token)
    } catch (error) {
      console.error('BPAuthProvider getAccessToken error', error, error?.error)
      // In case of any error, try login again
      // error.error can be: invalid_grant, missing_refresh_token, login_required
      props.login()
      // loginWithPopup() // We can optionally use this to login with popup
    }
  }

  useEffect(() => {
    if (user) {
      getAccessToken()
    }
  }, [user])

  // Set up global auth context
  useEffect(() => {
    setAuthContext({
      getAccessToken,
      accessToken,
      user,
      error,
      isLoading
    })
  }, [getAccessToken, accessToken, user, error, isLoading])

  const props = {
    login: () => {
      let authorizationParams: { [key: string]: string | undefined } = {}

      loginWithRedirect({
        authorizationParams,
      })
    },
    signup: () => {
      let authorizationParams: { [key: string]: string | undefined } = {}
      authorizationParams.screen_hint = SCREEN_HINT.signup

      loginWithRedirect({
        authorizationParams,
      })
    },
    logout: async () => {
      logout({ logoutParams: { returnTo: process.env.NEXT_PUBLIC_SITE_URL } })
      setAccessToken('')
      try {
        const allKeys = await AsyncStorage.getAllKeys()
        clearAccountInfo()
        clearUserInfo()
        streamStore?.clear()
        await AsyncStorage.multiRemove(allKeys)
      } catch (error) {
        console.error('Error removing local store')
      }
      return true
    },
    user,
    error,
    isLoading,
    accessToken,
    getAccessToken,
    isAuthenticated: !!(user && accessToken),
  }

  return <BPAuthContext.Provider value={{ ...props }}>{children}</BPAuthContext.Provider>
}

export const Auth0Provider = ({ children }: { children: React.ReactElement }) => (
  <AuthProvider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: auth0RedirectUri,
      audience: auth0Audience,
      scope: AUTH0_SCOPE,
    }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    // useRefreshTokensFallback={true}
  >
    <BPAuthProvider>{children}</BPAuthProvider>
  </AuthProvider>
)
