import { useState, useCallback } from 'react'
import API from 'app/api'

export function useSearchPeople() {
    const [searchResults, setSearchResults] = useState(null)
    const [searchText, setSearchText] = useState('')

    const handleSearch = useCallback(async (text) => {
        if (!text) return

        const searchResults = await API.search.getPeople(text.toLowerCase())
        setSearchResults(searchResults.data)
    }, [])

    const handleTextChange = useCallback((text) => {
        setSearchText(text)
        if (text === '') {
            clearSearch()
        } else {
            handleSearch(text)
        }
    }, [handleSearch])

    const clearSearch = useCallback(() => {
        setSearchResults(null)
        setSearchText('')
    }, [])

    return {
        searchResults,
        searchText,
        handleSearch,
        handleTextChange,
        clearSearch,
    }
} 