import { Text, Stack } from '@bp/ui'
import React from 'react'

export function NotificationBadge({ count = 0, ...rest }) {
  if (count < 1) {
    return
  }

  return (
    <Stack
      bc="$errorDarker"
      h={20}
      minWidth={20}
      pos="absolute"
      zIndex={99}
      px={6}
      br={10}
      transform={[{ translateX: 12 }, { translateY: -10 }]}
      ai="center"
      jc="center"
      {...rest}
    >
      <Text variant="caption3" color="$lightest">
        {count > 99 ? '99+' : count}
      </Text>
    </Stack>
  )
}
