import { create } from 'zustand'
import API from 'app/api'

// Retaining api response schema for the store
interface AccountInfoStore {
  isLoading: boolean
  error: object | boolean
  ready: boolean
  is_profile_private: boolean
  show_age: boolean
  show_birthday: boolean
  profile_status: string
  account_status: string
  username: string
  display_name: string
  email: string
  email_status: string
  location: string
  dob_y_m_d: string
  notifications: {
    posts: boolean
    popular: boolean
    mentions: boolean
    follows: boolean
    messages: boolean
    chats: boolean
  }
  gender: string
  available_genders: { id: number; value: string }[] | null
  password: string
  setAccountInfo: () => Promise<object>
  setPassword: (oldValue: string, newValue: string) => Promise<void>
  setUsername: (value: string) => Promise<object>
  setProfileStatus: () => Promise<object>
  setDisplayName: (value: string) => Promise<object>
  getIPLocation: () => Promise<object>
  setLocation: (value: string) => Promise<void>
  setEmail: (value: string) => Promise<any | { error?: any }>
}

const defaultProps = {
  isLoading: false,
  error: false,
  ready: false,
  is_profile_private: true,
  show_age: false,
  show_birthday: false,
  profile_status: '',
  account_status: '',
  username: '',
  display_name: '',
  email: '',
  email_status: '',
  location: '',
  dob_y_m_d: '',
  notifications: {
    posts: true,
    popular: true,
    mentions: true,
    follows: true,
    messages: true,
    chats: true,
  },
  gender: '',
  available_genders: null,
  password: '********',
  blockedUsers: '',
}

export const useAccountInfoStore = create<AccountInfoStore>((set) => ({
  ...defaultProps,
  setAccountInfo: async () => {
    set({ isLoading: true, error: false })
    try {
      //NOTE: When we are revisiting this module later, remove the fetch logic from store
      const response = await API.settings.accountInfo()
      set({ ...response, error: false, isLoading: false, ready: true })
      return response
    } catch (error) {
      set({ error, isLoading: false })
      return { error }
    }
  },
  setLocation: async (value) => {
    try {
      const response = await API.settings.setLocation(value)
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  getIPLocation: async () => {
    try {
      // TODO: Move values to env
      const response = await fetch(`https://extreme-ip-lookup.com/json/?key=beItD8DBrptuPKLzkxYt`)
      const { city, country } = await response.json()
      return {
        city,
        country,
      }
    } catch (error) {
      return { error }
    }
  },
  setUsername: async (value) => {
    try {
      const response = await API.settings.setUsername(value)
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  setProfileStatus: async () => {
    try {
      const response = await API.account.setProfile()
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  setDisplayName: async (value) => {
    try {
      const response = await API.settings.setDisplayName(value)
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  setEmail: async (value) => {
    try {
      const response = await API.settings.setEmail(value)
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  setDob: async (value) => {
    try {
      const response = await API.settings.setDob(value)
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  setNotifications: async (value) => {
    try {
      const response = await API.account.setNotifications(value)
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  setGender: async (value) => {
    try {
      const response = await API.settings.setGender(value)
      set({ ...response })
      return response
    } catch (error) {
      return { error }
    }
  },
  setPassword: async (oldValue, newValue) => {
    try {
      const response = await API.settings.setPassword(oldValue, newValue)
      return response
    } catch (error) {
      return { error }
    }
  },
  setInterests: async (interested) => {
    try {
      const response = await API.settings.setInterests(interested)
      return response
    } catch (error) {
      return { error }
    }
  },
  deactivateAccount: async () => {
    try {
      const response = await API.account.deactivate()
      return response
    } catch (error) {
      return { error }
    }
  },
  activateAccount: async () => {
    try {
      const response = await API.account.activate()
      return response
    } catch (error) {
      return { error }
    }
  },
  deleteAccount: async () => {
    try {
      const response = await API.account.delete()
      return response
    } catch (error) {
      return { error }
    }
  },
  startOnboardingTest: () => {
    set({ dob_y_m_d: '', location: '', gender: '', display_name: '' })
  },
  clear: () => {
    set({ ...defaultProps })
  },
}))
